import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    getPlanos() {
      return new Promise((resolve, reject) => {
        axios.get('/plano-agente')
          .then(response => resolve(response))
          .catch(error => { reject(error) })
      })
    },

    getAgenteAssinantes(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios.get('fut/agente/assinantes', { params: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    getCarteira(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios.get('fut/agente/assinante/carteira', { params: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    registrarUsuario(ctx, playload) {
      return new Promise((resolve, reject) => {
        axios.post('/fut/agente/assinante', playload)
          .then(response => resolve(response))
          .catch(error => { reject(error) })
      })
    },

    trocarAssinaturaPlano(ctx, playload) {
      return new Promise((resolve, reject) => {
        axios.put('/fut/agente/assinatura/trocar-plano', playload)
          .then(response => resolve(response))
          .catch(error => { reject(error) })
      })
    },

    trocarAssinaturaStatus(ctx, playload) {
      return new Promise((resolve, reject) => {
        axios.put('/fut/agente/assinatura/trocar-status', playload)
          .then(response => resolve(response))
          .catch(error => { reject(error) })
      })
    },

    getAssinaturaHistorico(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios.get('/agente/assinante/assinatura-historico', { params: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

  },
}
