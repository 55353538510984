<template>
  <div>
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>
          {{ $t('futures.agente.assinante.titleFiltros') }}
        </h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              :label="$t('futures.agente.assinante.tableHeader.email')"
              label-for="palavraChave"
            >
              <b-form-input
                id="palavraChave"
                v-model="palavraChaveFilter"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <label>{{ $t('futures.agente.assinante.tableHeader.statusAssinatura') }}</label>
            <v-select
              v-model="statusAgenteFilter"
              :options="statusAgenteOptions"
              :clearable="false"
              class="w-100"
            />
          </b-col>
          <b-col
            cols="12"
            md="2"
            class="mb-md-0 mb-2"
          >
            <label style="visibility:hidden">acao</label>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="form-control"
              @click.prevent="buscarFiltro"
            >
              {{ $t('futures.agente.assinante.pesquisarBtn') }}

            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <!-- Table Top -->
      <b-row
        v-if="$can('manager', 'PERMITE_CADASTRAR_USUARIO')"
        class="m-2"
      >
        <div class="mb-0 d-flex justify-content-end align-items-center w-100">
          <!-- Botão alinhado à direita -->
          <b-button
            v-b-modal.modal-novo-usuario
            variant="primary"
          >
            <span class="text-nowrap">{{ $t('futures.agente.assinante.novoUsuario') }}</span>
          </b-button>
        </div>
      </b-row>

      <b-table
        ref="refListTable"
        :items="dataList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('futures.emptyText')"
        :sort-desc.sync="isSortDirDesc"
        striped
        style="min-height: 250px"
      >

        <template #cell(dataInicio)="data">
          {{ formatDateBR(data.value) }}
        </template>

        <template #cell(statusAssinatura)="data">
          <b-badge :variant="data.value == 'Ativo'? 'light-success': 'light-danger' ">
            {{ data.value }}
          </b-badge>
        </template>

        !-- Column: Actions -->
        <template #cell(acao)="data">
          <b-dropdown
            variant="link"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              @click.stop="loadModalCarteira(data.item)"
            >
              <feather-icon icon="CreditCardIcon" />
              <span class="align-middle ml-50">{{ $t('futures.agente.assinante.verCarteira') }}</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="$can('manager', 'PERMITE_ALTERAR_ASSINATURA_AGENTE_PLANO_USUARIO')"
              v-b-modal.modal-trocar-plano
              @click.stop="loadModalTrocarAssinatura(data.item)"
            >
              <feather-icon icon="RepeatIcon" />
              <span class="align-middle ml-50">{{ $t('futures.agente.assinante.trocarPlano') }}</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="$can('manager', 'PERMITE_ALTERAR_ASSINATURA_AGENTE_STATUS_USUARIO')"
              v-b-modal.modal-trocar-assinatura-status
              @click.stop="loadModalTrocarAssinatura(data.item)"
            >
              <feather-icon :icon="data.item.statusAssinatura == 'Ativo'? 'ToggleLeftIcon': 'ToggleRightIcon'" />
              <span class="align-middle ml-50">
                {{ data.item.statusAssinatura == 'Ativo'? $t('futures.agente.assinante.finalizarAssinatura'): $t('futures.agente.assinante.reativarAssinatura') }}
              </span>
            </b-dropdown-item>

            <b-dropdown-item
              v-b-modal.modal-assinatura-historico
              @click.stop="loadModalAssinaturaHistorico(data.item)"
            >
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">
                {{ $t('futures.agente.assinante.historicoAssinatura') }}
              </span>
            </b-dropdown-item>

          </b-dropdown>

        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ $t('futures.textMuted', { from: paginationMeta.from, to: paginationMeta.to, of: paginationMeta.of }) }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalCount"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>

      <b-modal
        v-if="dataModal"
        id="modalcarteira"
        ref="modalcarteira"
        size="lg"
        centered
        :title="$t('futures.agente.assinante.verCarteira')"
        ok-only
        :ok-title="$t('futures.agente.assinante.sair')"
        hide-header-close="true"
        @ok="dataModal = null"
      >
        <b-row>
          <b-col
            xl="3"
            sm="6"
            class="mb-md-0 mb-xl-0"
          >
            <b-media no-body>
              <b-media-aside
                class="mr-2"
              >
                <b-avatar
                  src="https://cryptoboot.com.br/repo/icons/usdt.png"
                  size="45px"
                />
              </b-media-aside>
              <b-media-body class="my-auto">
                Saldo USDT
                <h2 class="font-weight-bolder">
                  {{ toFixed(dataModal.balanceUSD, 2) }}
                </h2>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col>
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              v-b-tooltip.hover.top="'Saldo disponível para entrar em novas operações'"
              variant="flat-success"
              class="btn-icon"
              block
            >
              <b-media no-body>
                <b-media-aside

                  class="mr-2"
                >
                  <b-avatar
                    size="48"
                    variant="light-success"
                  >
                    <feather-icon
                      size="24"
                      icon="DollarSignIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ toFixed(dataModal.disponivelUSD, 2) }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    Disponível para operar
                  </b-card-text>
                  <b-progress
                    :value="dataModal.disponivelUSDPerc"
                    max="100"
                    class="progress-bar-success"
                    variant="success"
                  />
                </b-media-body>
              </b-media>
            </b-button>
          </b-col>
          <b-col>
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              v-b-tooltip.hover.top="'O saldo de margem, é o seu saldo adicionando ou reduzindo o valor das operações em aberto'"
              variant="flat-warning"
              class="btn-icon"
              block
            >
              <b-media no-body>
                <b-media-aside

                  class="mr-2"
                >
                  <b-avatar
                    size="48"
                    variant="light-warning"
                  >
                    <feather-icon
                      size="24"
                      icon="BarChart2Icon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ parseFloat(dataModal.marginBalanceUSD).toFixed(2) }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    Saldo de margem
                  </b-card-text>
                  <b-progress
                    :value="dataModal.marginBalanceUSDPerc"
                    max="100"
                    class="progress-bar-warning"
                    variant="warning"
                  />
                </b-media-body>
              </b-media>
            </b-button>
          </b-col>
          <b-col>
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              v-b-tooltip.hover.top="'Variação das operações em aberto com base no preço de mercado'"
              variant="flat-primary"
              class="btn-icon"
              block
            >
              <b-media no-body>
                <b-media-aside

                  class="mr-2"
                >
                  <b-avatar
                    size="48"
                    variant="light-primary"
                  >
                    <feather-icon
                      size="24"
                      icon="ActivityIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ parseFloat(dataModal.unrealizedPNLUSD).toFixed(2) }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    Operações em aberto
                  </b-card-text>
                  <b-progress
                    :value="dataModal.unrealizedPNLUSDPerc"
                    max="100"
                  />
                </b-media-body>
              </b-media>
            </b-button>
          </b-col>
        </b-row>
      </b-modal>

      <b-modal
        id="modal-novo-usuario"
        ref="modal-novo-usuario"
        cancel-variant="outline-secondary"
        :ok-title="$t('futures.agente.assinante.adicionar')"
        :cancel-title="$t('futures.agente.assinante.sair')"
        :title="$t('futures.agente.assinante.novoUsuario')"
        hide-header-close
        centered
        no-close-on-backdrop
        @show="showModalNovoUsuario"
        @ok="gerenciarNovoUsuarioHandler"
      >
        <b-overlay
          :show="loadingOverlayCadastro"
          rounded="sm"
        >
          <validation-observer ref="formnovousuario">
            <b-form>
              <b-form-group>
                <label for="plano">{{ $t('futures.agente.assinante.plano') }}</label>
                <validation-provider
                  #default="{ errors }"
                  name="Plano"
                  rules="required"
                >
                  <b-form-select
                    v-model="cadastro.plano"
                    :options="planos"
                    input-id="plano"
                    value-field="id"
                    text-field="nome"
                    :state="errors.length > 0? false: null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <label for="variacao">{{ $t('futures.agente.assinante.variacao') }}</label>
                <validation-provider
                  #default="{ errors }"
                  name="variacao"
                  rules="required"
                >
                  <b-form-select
                    v-model="cadastro.planoVariacao"
                    :options="variacaoSelected"
                    input-id="variacao"
                    value-field="id"
                    text-field="descricao"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <label for="nome">{{ $t('futures.agente.assinante.nome') }}</label>
                <validation-provider
                  #default="{ errors }"
                  name="Nome"
                  rules="required|min:3"
                >
                  <b-form-input
                    id="nome"
                    v-model="cadastro.nome"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <label for="email">{{ $t('futures.agente.assinante.email') }}</label>
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="email"
                    v-model="cadastro.email"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <label for="password">{{ $t('futures.agente.assinante.senha') }}</label>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required|min:6"
                >
                  <b-form-input
                    id="password"
                    v-model="cadastro.senha"
                    :state="errors.length > 0 ? false:null"
                    name="register-password"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

            </b-form>
          </validation-observer>
        </b-overlay>
      </b-modal>

      <b-modal
        id="modal-trocar-plano"
        ref="modal-trocar-plano"
        cancel-variant="outline-secondary"
        :ok-title="$t('futures.agente.assinante.editar')"
        :cancel-title="$t('futures.agente.assinante.sair')"
        :title="$t('futures.agente.assinante.alterarAssinatura')"
        hide-header-close
        centered
        no-close-on-backdrop
        @show="showModalTrocaAssinaturaPlano"
        @ok="gerenciarTrocaAssinaturaPlanoHandler"
      >
        <b-overlay
          :show="loadingOverlayCadastro"
          rounded="sm"
        >
          <validation-observer ref="formTrocaPlano">
            <b-form>
              <b-form-group>
                <label for="email">{{ $t('futures.agente.assinante.email') }}</label>
                <b-form-input
                  id="email"
                  v-model="cadastroEditar.email"
                  readonly
                />
              </b-form-group>

              <b-form-group>
                <label for="plano">{{ $t('futures.agente.assinante.plano') }}</label>
                <validation-provider
                  #default="{ errors }"
                  name="Plano"
                  rules="required"
                >
                  <b-form-select
                    v-model="cadastroEditar.plano"
                    :options="planos"
                    input-id="plano"
                    value-field="id"
                    text-field="nome"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <label for="variacao">{{ $t('futures.agente.assinante.variacao') }}</label>
                <validation-provider
                  #default="{ errors }"
                  name="variacao"
                  rules="required"
                >
                  <b-form-select
                    v-model="cadastroEditar.planoVariacao"
                    :options="variacaoSelectedEditar"
                    input-id="variacao"
                    value-field="id"
                    text-field="descricao"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-form>
          </validation-observer>
        </b-overlay>
      </b-modal>

      <b-modal
        id="modal-trocar-assinatura-status"
        ref="modal-trocar-assinatura-status"
        cancel-variant="outline-secondary"
        :ok-title="cadastroEditar.statusAssinatura == 'Ativo'? $t('futures.agente.assinante.finalizarAssinatura'): $t('futures.agente.assinante.reativarAssinatura')"
        :cancel-title="$t('futures.agente.assinante.sair')"
        :title="$t('futures.agente.assinante.alterarAssinatura')"
        hide-header-close
        centered
        no-close-on-backdrop
        @show="showModalTrocarAssinaturaStatus"
        @ok="gerenciarTrocarAssinaturaStatusHandler"
      >
        <b-overlay
          :show="loadingOverlayCadastro"
          rounded="sm"
        >
          <validation-observer ref="formTrocarAssinaturaStatus">
            <b-form>

              <b-form-group>
                <label for="nome">{{ $t('futures.agente.assinante.nome') }}</label>
                <b-form-input
                  id="nome"
                  v-model="cadastroEditar.nome"
                  readonly
                />
              </b-form-group>

              <b-form-group>
                <label for="email">{{ $t('futures.agente.assinante.email') }}</label>
                <b-form-input
                  id="email"
                  v-model="cadastroEditar.email"
                  readonly
                />
              </b-form-group>

              <b-form-group>
                <label for="status">{{ $t('futures.agente.assinante.statusAssinatura') }}</label>
                <div>
                  <b-badge :variant="cadastroEditar.statusAssinatura == 'Ativo'? 'light-success': 'light-danger' ">
                    {{ cadastroEditar.statusAssinatura }}
                  </b-badge>
                </div>
              </b-form-group>

            </b-form>
          </validation-observer>
        </b-overlay>
      </b-modal>

      <b-modal
        id="modal-assinatura-historico"
        ref="modal-assinatura-historico"
        :title="$t('futures.agente.assinante.historicoAssinatura')"
        centered
        size="lg"
        ok-only
        :ok-title="$t('futures.agente.assinante.sair')"
        hide-header-close
      >
        <b-overlay
          :show="loadingOverlayCadastro"
          rounded="sm"
        >
          <b-table-lite
            sticky-header="300px"
            hover
            responsive
            :items="dataListAssinaturaHistorico"
            :fields="tableHistoricoAssinantes"
          >

            <template #cell(status)="data">
              <div class="text-nowrap">
                <b-badge :variant="data.value == 'Ativo'? 'light-success': 'light-danger' ">
                  {{ data.value }}
                </b-badge>
              </div>
            </template>

            <template #cell(plano)="data">
              <div class="text-nowrap">
                {{ data.value.nome }}
              </div>
            </template>

            <template #cell(variacao)="data">
              <div class="text-nowrap">
                {{ data.value.nome }}
              </div>
            </template>

            <template #cell(dataInicio)="data">
              <div class="text-nowrap">
                {{ formattedDateMoment(data.value) }}
              </div>
            </template>

            <template #cell(dataFim)="data">
              <div
                v-if="data.value"
                class="text-nowrap"
              >
                {{ formattedDateMoment(data.value) }}
              </div>
            </template>

          </b-table-lite>
        </b-overlay>
      </b-modal>

    </b-card>
  </div>
</template>

<script>
import {
  BCard, BTable, BPagination, BRow, BCol, BCardText, BCardBody, BButton, BDropdown, BDropdownItem, BBadge, BProgress, BAvatar, BMediaAside, BMediaBody, BMedia, BCardHeader, BOverlay, BForm, BFormGroup, BFormInput, BFormSelect, VBTooltip, BTableLite,
} from 'bootstrap-vue'
import store from '@/store'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import moment from 'moment'
import utilsMixin from '@/mixins/utilsMixin'
import { formatDateBR } from '@/utils/filter'
import agenteAssinanteStoreModule from './assinanteStoreModule'

const AGENTE_ASSINANTE_FUT_STORE_MODULE_NAME = 'agente-assinante-fut'

export default {

  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },

  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BCardHeader,
    BTable,
    BPagination,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BBadge,
    BProgress,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BMedia,
    BCardText,
    BCardBody,
    BButton,
    vSelect,
    BFormSelect,
    BOverlay,
    BForm,
    BFormGroup,
    BFormInput,
    BTableLite,
  },

  mixins: [utilsMixin],

  data() {
    return {
      required,
      formatDateBR,
      perPage: 15,
      totalCount: 0,
      currentPage: 1,
      sortBy: 'id,desc',
      isSortDirDesc: true,
      dataList: [],
      dataModal: null,
      statusAgenteFilter: null,
      palavraChaveFilter: null,
      novoUsuario: null,
      novoUsuarioState: null,
      planos: [],
      loadingOverlayCadastro: false,
      cadastro: {
        nome: null,
        email: null,
        senha: null,
        plano: [],
        planoVariacao: [],
        statusAssinatura: null,
      },
      cadastroEditar: {
        nome: null,
        senha: null,
        plano: [],
        planoVariacao: [],
        statusAssinatura: null,
      },
      dataListAssinaturaHistorico: [],
    }
  },

  computed: {

    variacaoSelected() {
      const plano = this.planos.find(v => v.id === this.cadastro.plano)
      if (plano != null) {
        return plano.planoAgenteVariacao
      }
      return null
    },

    variacaoSelectedEditar() {
      const plano = this.planos.find(v => v.id === this.cadastroEditar.plano)
      if (plano != null) {
        return plano.planoAgenteVariacao
      }
      return null
    },

    statusAgenteOptions() {
      return [
        { label: this.$i18n.t('futures.agente.assinante.todos'), value: null },
        { label: this.$i18n.t('futures.agente.assinante.inativo'), value: '3' },
        { label: this.$i18n.t('futures.agente.assinante.ativo'), value: '1' },
      ]
    },

    tableColumns() {
      return [
        { key: 'nome', label: this.$i18n.t('futures.agente.assinante.tableHeader.nome') },
        { key: 'email', label: this.$i18n.t('futures.agente.assinante.tableHeader.email') },
        { key: 'planoNome', label: this.$i18n.t('futures.agente.assinante.tableHeader.planoNome') },
        { key: 'planoVariacaoNome', label: this.$i18n.t('futures.agente.assinante.tableHeader.planoVariacaoNome') },
        { key: 'statusAssinatura', label: this.$i18n.t('futures.agente.assinante.tableHeader.statusAssinatura') },
        { key: 'dataInicio', label: this.$i18n.t('futures.agente.assinante.tableHeader.dataInicio') },
        { key: 'acao', label: this.$i18n.t('futures.agente.assinante.tableHeader.acao') },
      ]
    },

    tableHistoricoAssinantes() {
      return [
        { key: 'plano', label: this.$i18n.t('futures.agente.assinante.tableHeader.planoNome') },
        { key: 'variacao', label: this.$i18n.t('futures.agente.assinante.tableHeader.planoVariacaoNome') },
        { key: 'status', label: this.$i18n.t('futures.agente.assinante.tableHeader.statusAssinatura') },
        { key: 'dataInicio', label: this.$i18n.t('futures.agente.assinante.tableHeader.dataInicio') },
        { key: 'dataFim', label: this.$i18n.t('futures.agente.assinante.tableHeader.dataFim') },
      ]
    },

    paginationMeta() {
      const localItemsCount = this.$refs.refListTable ? this.$refs.refListTable.localItems.length : 0
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalCount,
      }
    },

  },

  watch: {
    currentPage() {
      this.fetchList()
      this.$refs.refListTable.refresh()
    },
  },

  created() {
    if (!store.hasModule(AGENTE_ASSINANTE_FUT_STORE_MODULE_NAME)) store.registerModule(AGENTE_ASSINANTE_FUT_STORE_MODULE_NAME, agenteAssinanteStoreModule)
    this.fetchList()
    this.getPlanos()
  },

  beforeDestroy() {
    if (store.hasModule(AGENTE_ASSINANTE_FUT_STORE_MODULE_NAME)) store.unregisterModule(AGENTE_ASSINANTE_FUT_STORE_MODULE_NAME)
  },

  methods: {

    formattedDateMoment(originalDate) {
      return moment(originalDate).format('DD/MM/YYYY')
    },

    formattedDateTimeMoment(originalDate) {
      return moment(originalDate).format('DD/MM/YYYY HH:mm:ss')
    },

    limparCadastro() {
      this.cadastro = {
        nome: null,
        email: null,
        senha: null,
        plano: [],
        planoVariacao: [],
        statusAssinatura: null,
      }
    },

    limparCadastroEditar() {
      this.cadastroEditar = {
        nome: null,
        email: null,
        senha: null,
        plano: [],
        planoVariacao: [],
        statusAssinatura: null,
      }
    },

    // novo usuario
    showModalNovoUsuario() {
      this.limparCadastro()
      this.novoUsuario = null
      this.novoUsuarioState = null
    },

    gerenciarNovoUsuarioHandler(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.gerenciarNovoUsuarioSubmit()
    },

    gerenciarNovoUsuarioSubmit() {
      // Exit when the form isn't valid
      this.$refs.formnovousuario.validate().then(success => {
        if (success) {
          this.registrarUsuario()
        }
        this.novoUsuario = success
      })
    },

    registrarUsuario() {
      this.loadingOverlayCadastro = true
      this.$store.dispatch('agente-assinante-fut/registrarUsuario', this.cadastro)
        .then(() => {
          this.$nextTick(() => {
            this.$refs['modal-novo-usuario'].toggle('#toggle-btn')
          })

          this.$swal({
            icon: 'success',
            title: this.$t('futures.agente.assinante.cadastroSucessoTitle'),
            customClass: {
              confirmButton: 'btn btn-success',
            },
            buttonsStyling: false,
          })

          this.fetchList()

          this.limparCadastro()
          this.$refs.formnovousuario.reset()
        })
        .catch(error => {
          this.$swal({
            title: 'Error!',
            text: error.data[0].erro,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
        .finally(() => {
          this.loadingOverlayCadastro = false
        })
    },

    // trocar status assinatura
    showModalTrocarAssinaturaStatus() {
      this.novoUsuario = null
      this.novoUsuarioState = null
    },

    gerenciarTrocarAssinaturaStatusHandler(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.$refs.formTrocarAssinaturaStatus.validate().then(success => {
        if (success) {
          this.trocarAssinaturaStatus()
        }
        this.novoUsuario = success
      })
    },

    trocarAssinaturaStatus() {
      this.loadingOverlayCadastro = true

      this.cadastroEditar.statusAssinatura = this.cadastroEditar.statusAssinatura === 'Ativo' ? 'FINALIZADO' : 'ATIVO'

      this.$store.dispatch('agente-assinante-fut/trocarAssinaturaStatus', this.cadastroEditar)
        .then(() => {
          this.$nextTick(() => {
            this.$refs['modal-trocar-assinatura-status'].toggle('#toggle-btn')
          })

          this.$swal({
            icon: 'success',
            title: this.$t('futures.agente.assinante.assinaturadaEditadaSucessoTitle'),
            customClass: {
              confirmButton: 'btn btn-success',
            },
            buttonsStyling: false,
          })

          this.fetchList()

          this.limparCadastroEditar()
          this.$refs.formTrocarAssinaturaStatus.reset()
        })
        .catch(error => {
          this.$swal({
            title: 'Error!',
            text: error.data[0].erro,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
        .finally(() => {
          this.loadingOverlayCadastro = false
        })
    },

    // trocar plano
    showModalTrocaAssinaturaPlano() {
      this.novoUsuario = null
      this.novoUsuarioState = null
    },

    gerenciarTrocaAssinaturaPlanoHandler(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.$refs.formTrocaPlano.validate().then(success => {
        if (success) {
          this.trocarAssinaturaPlano()
        }
        this.novoUsuario = success
      })
    },

    trocarAssinaturaPlano() {
      this.loadingOverlayCadastro = true
      this.$store.dispatch('agente-assinante-fut/trocarAssinaturaPlano', this.cadastroEditar)
        .then(() => {
          this.$nextTick(() => {
            this.$refs['modal-trocar-plano'].toggle('#toggle-btn')
          })

          this.$swal({
            icon: 'success',
            title: this.$t('futures.agente.assinante.assinaturadaEditadaSucessoTitle'),
            customClass: {
              confirmButton: 'btn btn-success',
            },
            buttonsStyling: false,
          })

          this.fetchList()

          this.limparCadastroEditar()
          this.$refs.formTrocaPlano.reset()
        })
        .catch(error => {
          this.$swal({
            title: 'Error!',
            text: error.data[0].erro,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
        .finally(() => {
          this.loadingOverlayCadastro = false
        })
    },

    getPlanos() {
      this.$store.dispatch('agente-assinante-fut/getPlanos')
        .then(response => {
          this.planos = response.data
        })
    },

    // historico de assinatura
    loadModalAssinaturaHistorico(item) {
      this.loadingOverlayCadastro = true
      store
        .dispatch('agente-assinante-fut/getAssinaturaHistorico', { usuarioId: item.idUsuario })
        .then(response => {
          this.dataListAssinaturaHistorico = response.data
          setTimeout(() => {
            this.$bvModal.show('modalAssinaturaHistorico')
          }, 100)
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro ao consultar o histórico de assinatura.',
              text: error.data,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.loadingOverlayCadastro = false
        })
    },

    loadModalTrocarAssinatura(item) {
      this.cadastroEditar = { ...item }

      const planTemp = this.planos.find(plano => plano.nome === item.planoNome)
      this.cadastroEditar.plano = planTemp.id

      const planVariacaoTemp = planTemp.planoAgenteVariacao.find(pa => pa.nome === item.planoVariacaoNome)
      this.cadastroEditar.planoVariacao = planVariacaoTemp.id
    },

    loadModalCarteira(item) {
      store
        .dispatch('agente-assinante-fut/getCarteira', { id: item.idUsuario })
        .then(response => {
          this.dataModal = response.data
          setTimeout(() => {
            this.$bvModal.show('modalcarteira')
          }, 100)
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro ao consultar a carteira.',
              text: error.data,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },

    buscarFiltro() {
      this.currentPage = 1
      this.fetchList()
    },

    fetchList() {
      if (!this.statusAgenteFilter) {
        this.statusAgenteFilter = this.statusAgenteOptions.find(option => option.value === '1')
      }

      store
        .dispatch('agente-assinante-fut/getAgenteAssinantes', {
          size: this.perPage,
          page: this.currentPage - 1,
          sortBy: this.sortBy,
          sortDesc: this.isSortDirDesc,
          status: this.statusAgenteFilter.value,
          chave: this.palavraChaveFilter,
        })
        .then(response => {
          const { content, totalElements } = response.data
          this.dataList = content
          this.totalCount = totalElements
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro ao recuperar a lista.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },

  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div ::v-deep {
      .b-overlay {
        .bg-light {
          background-color: $theme-dark-body-bg !important;
        }
      }
  }
}
</style>
